<!--  -->
<template>
<div class=''>
    <div
      v-for="image in images"
      :key="image.id"
      class="image-item"
      @click="showImage(image)"
    >
      <img :src="image.src" alt=""/>
      <p>{{ image.id }}</p>
    </div>
    <div v-if="isShow">暂无上传图片</div>
</div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
//import引入的组件需要注入到对象中才能使用
components: {},
data() {
//这里存放数据
return {
    images: [],
    id: 1,
    uid: 2,
    isShow:true
};
},
//监听属性 类似于data概念
computed: {},
//监控data中的数据变化
watch: {},
//方法集合
methods: {
    async loadImages() {
      // Call backend API to retrieve images and their ids
      var uid = this.uid
      const response = await fetch(`https://www.xin-gou.com/api/personal/photo/get/${uid}`);
    
      const data = await response.json();

      // Map the response data to format required by the image-grid
      this.images = data.map(image => ({
        type: image.type,
        src: image.photo
      }));
    },
    showImage(image) {
      // Show a modal with the image details, or navigate to a new view
      alert(`查看图片 ${image.id}`);
    },
    getImage(){
        var uid = this.uid
        var that = this
        this.$http({
            url: this.$http.adornUrl(`/personal/photo/get/${uid}`),
            method: 'get',
        }).then(({ data }) => { 
            if (data && data.code === 0) {
                // var blob = new Blob([data.data], { type: 'image/png' }); // 使用jpeg
                // console.log(blob);
                var temp = data.data
                temp.forEach(async(item) => {
                    var src = `data:image/jpeg;base64, ${item.photo}`
                    var type = item.type
                    that.images.push({"src":src, "type":type})
                })
                if (! that.images.length === 0){
                    that.isShow = false
                }
            } else {
                // this.getCaptcha()
                this.$message.error(data.msg)
            }
        })
    }
},
//生命周期 - 创建完成（可以访问当前this实例）
created() {
    if (this.$route.query.uid != null && this.$route.query.uid != undefined) {
      this.uid = this.$route.query.uid
    }
},
//生命周期 - 挂载完成（可以访问DOM元素）
mounted() {
    this.getImage();
},
beforeCreate() {}, //生命周期 - 创建之前
beforeMount() {}, //生命周期 - 挂载之前
beforeUpdate() {}, //生命周期 - 更新之前
updated() {}, //生命周期 - 更新之后
beforeDestroy() {}, //生命周期 - 销毁之前
destroyed() {}, //生命周期 - 销毁完成
activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.image-item {
  width: 30%;
  margin-bottom: 1em;
  cursor: pointer;
}

.image-item img {
  width: 100%;
}

.image-item p {
  text-align: center;
}
</style>